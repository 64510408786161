





















import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import AnalysisPlanCreationFormComponent from '@/components/Management/organisms/AnalysisPlanCreationForm.vue';
import AnalysisPlanTableComponent from '@/components/Management/molecules/AnalysisPlanTable.vue';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        AnalysisPlanCreationForm: AnalysisPlanCreationFormComponent,
        AnalysisPlanTable: AnalysisPlanTableComponent,
    },
    setup() {
        const { company, isPlanFetchListProgressing } = useCompanyPlan();

        return {
            company,
            isPlanFetchListProgressing,
        };
    },
});
