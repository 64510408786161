






































































































import { defineComponent, ref } from '@vue/composition-api/dist/vue-composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useAnalysisPlanCreationForm } from '@/composables/store/Management/AnalysisPlan/useAnalysisPlanCreationForm';
import { useFormValidation } from '@/composables/function/Management/useFormValidation';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { useCompanyContractConfirmDialog } from '@/composables/store/Management/AnalysisPlan/useCompanyContractConfirmDialog';
import { VForm } from '@/types/common';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { error, analysisPlan, addPlan, initPlan, isPlanCreateProgressing, standardPlanFlag } =
            useAnalysisPlanCreationForm();
        const { analysisPlanCreationRules } = useFormValidation();
        const { fetchPlanList, setSelectedPlan } = useCompanyPlan();
        const { openContractDialog } = useCompanyContractConfirmDialog();
        const valid = ref(false);
        const form = ref<VForm>();

        const add = async (): Promise<void> => {
            const result = await addPlan();
            if (form.value) form.value.resetValidation();
            if (typeof result !== 'boolean' && result.data.data.id) {
                initPlan();
                await fetchPlanList();
                setSelectedPlan(result.data.data.id);
                openContractDialog();
            }
        };

        const loadingClass = (): string => (isPlanCreateProgressing.value ? 'deleting' : '');

        return {
            error,
            form,
            analysisPlan,
            valid,
            analysisPlanCreationRules,
            isPlanCreateProgressing,
            loadingClass,
            add,
            standardPlanFlag,
        };
    },
});
